import React, { Component } from 'react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Helmet from 'react-helmet';

import styled from 'styled-components';

import '../globalStyles.css';
import { bubbleSvg } from '../helpers';
import { lightTeal } from '../colors';
import Layout from '../components/Layout';

const ContactBackground = styled.div`
  background-color: var(--color-blue);
  flex-grow: 1;
  background-image: ${bubbleSvg(lightTeal, 25)};
  background-size: 30%;
  background-position: 100% 10%;
  background-repeat: no-repeat;

  @media (min-width: 1921px) {
    background-position: 105% 5%;
  }

  @media (max-width: 1620px) {
    background-position: 110% 20%;
  }

  @media (max-width: 1350px) {
    background-position: 110% 25%;
  }

  @media (max-width: 1150px) {
    background-position: 110% 30%;
  }

  @media (max-width: 1023px) {
    background-image: none;
  }
`;

const WidthLimitationContainer = styled.div`
  max-width: 1920px;
  margin: auto;
  display: flex;

  @media (max-width: 1023px) {
    display: block;
  }
`;

const TextContainer = styled.div`
  margin-right: -10%;

  @media (max-width: 1350px) {
    margin-right: -20%;
  }

  @media (max-width: 1100px) {
    margin-right: -30%;
  }

  @media (max-width: 1023px) {
    margin-right: 0;
  }
`;

const ContactHeader = styled.header`
  text-align: center;
  margin-bottom: 5%;

  & h1 {
    font-size: 60px;
    text-align: left;
    margin-left: 150px;
    padding-top: 8%;
    margin-bottom: 0;
    margin-top: 0;
    color: var(--color-yellow);
    white-space: pre-wrap;

    @media (max-width: 1620px) {
      padding-top: 4%;
    }

    @media (max-width: 1350px) {
      margin-left: 100px;
    }

    @media (max-width: 1023px) {
      margin-left: 0;
      text-align: center;
      max-width: 90%;
      margin: auto;
    }

    @media (max-width: 900px) {
      font-size: 50px;
    }

    @media (max-width: 550px) {
      font-size: 30px;
      padding-top: 5%;
    }
  }

  & p {
    margin-bottom: 0;
  }

  & h2 {
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0;
    margin-top: 0;
    text-align: left;
    margin-left: 150px;
    white-space: pre-wrap;
    color: var(--color-yellow);
    white-space: pre-wrap;

    @media (max-width: 1350px) {
      margin-left: 100px;
    }

    @media (max-width: 1023px) {
      margin-left: 0;
      text-align: center;
    }

    @media (max-width: 900px) {
      font-size: 18px;
    }

    @media (max-width: 550px) {
      font-size: 16px;
      max-width: 90%;
      margin: auto;
    }

    @media (max-width: 460px) {
      white-space: inherit;
    }
  }
`;

const SuccessText = styled.p`
  font-weight: 700;
  font-size: 20px;
  margin: 200px auto;
  text-align: center;
  color: var(--color-light-yellow);

  @media (max-widht: 1023px) {
    margin: 100px auto;
  }
`;

const ContactContainer = styled.div`
  display: flex;
  margin-left: 150px;
  padding-bottom: 150px;
  position: relative;

  @media (max-width: 1350px) {
    margin-left: 100px;
  }

  @media (max-width: 1023px) {
    margin-left: 0;
    justify-content: center;
    padding-bottom: 100px;
  }

  @media (max-width: 550px) {
    padding-top: 3%;
  }
`;

const ContactForm = styled.form`
  & span {
    color: var(--color-yellow);
    font-weight: 700;
    margin-top: 10px;
    padding: 5px 0;

    @media (max-width: 750px) {
      white-space: pre-wrap;
    }

    @media (max-width: 550px) {
      font-size: 15px;
    }
  }
`;

const ContactFormContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 550px) {
    width: min-content;
  }

  
  & label, input, textarea {
    width: 35vw;
    max-width: 600px;

    @media (max-width: 1023px) {
      width: 70vw;
    }

    @media (max-width: 550px) {
      width: 85vw;
    }
  }

  & label {
    margin-top: 25px;
    font-size: 20px;
    font-weight: 400;
    color: var(--color-white);

    @media (max-width: 800px) {
      font-size: 18px;
    }

    @media (max-width: 550px) {
      font-size: 16px;
    }
  }

  & input, textarea {
    margin-top: 10px;
    border: none;
    border-bottom: 2px solid var(--color-white);
    font-size: 20px;
    background-color: transparent;
    color: var(--color-yellow);

    &::placeholder {
      color: var(--color-yellow);
      font-size: 15px;

      @media (max-width: 550px) {
        font-size: 13px;
      }
    }

    @media (max-width: 800px) {
      font-size: 18px;
    }

    @media (max-width: 550px) {
      font-size: 17px;
    }
  }

  & textarea {
    resize: none;
  };

  & input:focus, textarea:focus {
    outline: none;
  }

  & p {
    font-size: 15px;
    color: var(--color-white);
    margin-bottom: 30px;

    @media (max-width: 550px) {
      font-size: 13px;
    }
  }

`;

const SubmitButton = styled.button`
  width: 180px;
  margin-top: 50px;
  color: var(--color-yellow);

  @media (max-width: 1023px) {
    margin: 40px auto 0 auto;
  }

  @media (max-width: 550px) {
    font-size: 16px;
    width: 140px;
    margin-top: 30px;
  }
`;

const ImageWrapper = styled.div`
  width: 35%;
  padding-top: 33.89%;
  position: relative;
  perspective: min(130vw, calc(1.3 * 1920px));
  flex-shrink: 0;
  flex-grow: 0;
  height: 0;
  margin: auto;

  @media (max-width: 1350px) {
    width: 40%;
    padding-top: 40.57%;
    perspective: min(140vw, calc(1.4 * 1920px));
  }

  @media (max-width: 1023px) {
    display: none;
  }
`;

const ContactPageMonitor = styled.div`
  background-image: url(/img/mockup_left.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position-y: center;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
`;

const ScreenContainer = styled.div`
  background-image: url("${(props) => props.url}");
  background-size: contain;
  background-repeat: no-repeat;
  background-position-y: center;
  width: 104%;
  height: 60%;
  position: absolute;
  left: -4.2%;
  top: 7.9%;
  transform: rotate3d(-0.76,1.55,-0.205,-46.7deg); //(x,y,z,angle)
  transform-origin: center;

  @media (max-width: 1350px) {
    width: 103%;
    height: 58%;
    top: 9.4%;
  }
`;

// eslint-disable-next-line max-len, no-useless-escape
const emailRegEx = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i; // "/i" is to make it case-insensitive
const phoneRegEx = /^[\d+\-() /.]*$/;

export default class Contact extends Component {
  static propTypes = {
    data: PropTypes.object,
    pageContext: PropTypes.object,
  };

  nameEl = null;

  emailEl = null;

  messageEl = null;

  phoneEl = null;

  constructor(props) {
    super(props);
    this.state = {
      name: '',
      company: '',
      email: '',
      phone: '',
      message: '',
      isValidEmail: true,
      isValidMessage: true,
      isValidName: true,
      isValidPhoneNumber: true,
      success: false,
      error: false,
    };
  }

  submitForm = () => {
    const {
      email, name, phone, company, message,
    } = this.state;

    const data = {
      submittedAt: Date.now(),
      email,
      name,
      phone,
      company,
      message,
    };

    data.message = `<br/>${data.message.replace(/(?:\r\n|\r|\n)/g, '<br/>')}`;

    fetch('https://wmgtdq1duk.execute-api.eu-west-1.amazonaws.com/prd/contactForm', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((responseData) => {
        console.log('Success:', responseData);
        this.setState({ success: true });
      })
      .catch((error) => {
        console.error('Error:', error);
        this.setState({ error: true });
      });
  };

  validateForm = (e) => {
    const {
      email, message, phone, name,
    } = this.state;
    e.preventDefault();

    this.setState(
      {
        isValidEmail: emailRegEx.test(email),
        isValidMessage: message.trim() !== '',
        isValidPhoneNumber: (phoneRegEx.test(phone) || phone.trim() === ''),
        isValidName: name.trim() !== '',
        success: false,
        error: false,
      },
      () => {
        const {
          isValidEmail, isValidMessage, isValidPhoneNumber, isValidName,
        } = this.state;
        if (!isValidName && this.nameEl) {
          this.nameEl.focus();
        } else if (!isValidEmail && this.emailEl) {
          this.emailEl.focus();
        } else if (!isValidPhoneNumber && this.phoneEl) {
          this.phoneEl.focus();
        } else if (!isValidMessage && this.messageEl) {
          this.messageEl.focus();
        }

        if (isValidEmail && isValidMessage && isValidPhoneNumber && isValidName) {
          this.submitForm();
        }
      }
    );
  };

  render() {
    const { data, pageContext } = this.props;
    const content = data?.mdx?.frontmatter || 'CONTENT NOT FOUND';

    const {
      email,
      name,
      phone,
      company,
      message,
      success,
      error,
      isValidName,
      isValidEmail,
      isValidPhoneNumber,
      isValidMessage,
    } = this.state;

    let canonicalLinkPrefix;

    if (pageContext.locale === 'en') {
      canonicalLinkPrefix = 'https://presono.com/en';
    } else {
      canonicalLinkPrefix = 'https://presono.com';
    }

    if (success) {
      return (
        <Layout currentPath={pageContext.originalPath} backgroundColor="var(--color-blue)">
          <Helmet>
            <title>{`${data?.mdx?.frontmatter?.title} | presono`}</title>
            <meta
              name="description"
              content={data?.mdx?.frontmatter?.snippetTextContact}
            />

            <link rel="canonical" href={canonicalLinkPrefix + pageContext.originalPath} />

            <meta property="og:title" content={data?.mdx?.frontmatter?.title} />
            <meta property="og:description" content={data?.mdx?.frontmatter?.snippetTextContact} />
            <meta name="twitter:title" content={data?.mdx?.frontmatter?.title} />
            <meta name="twitter:description" content={data?.mdx?.frontmatter?.snippetTextContact} />
          </Helmet>
          <ContactBackground tabIndex="-1">
            <main>
              <SuccessText>{content?.successText}</SuccessText>
            </main>
          </ContactBackground>
        </Layout>
      );
    }

    return (
      <Layout currentPath={pageContext.originalPath} backgroundColor="var(--color-blue)">
        <Helmet>
          <title>{`${data?.mdx?.frontmatter?.title} | presono`}</title>
          <meta
            name="description"
            content={data?.mdx?.frontmatter?.snippetTextContact}
          />

          <link rel="canonical" href={canonicalLinkPrefix + pageContext.originalPath} />

          <meta property="og:title" content={data?.mdx?.frontmatter?.title} />
          <meta property="og:description" content={data?.mdx?.frontmatter?.snippetTextContact} />
          <meta name="twitter:title" content={data?.mdx?.frontmatter?.title} />
          <meta name="twitter:description" content={data?.mdx?.frontmatter?.snippetTextContact} />
        </Helmet>
        <ContactBackground tabIndex="-1">
          <WidthLimitationContainer>
            <TextContainer>
              <ContactHeader>
                <h1>
                  {content?.headline}
                </h1>
                <h2>
                  <MDXRenderer>{content?.subtext}</MDXRenderer>
                </h2>
              </ContactHeader>
              <ContactContainer>
                <ContactForm onSubmit={this.validateForm} noValidate="novalidate">
                  <ContactFormContainer>
                    <label htmlFor="name">
                      {content?.nameLabel}
                    </label>
                    <input
                      type="text"
                      ref={(el) => {
                        if (el) this.nameEl = el;
                      }}
                      onChange={(e) => { this.setState({ name: e.target.value }); }}
                      value={name}
                      placeholder={content?.namePlaceholder}
                      id="name"
                    />
                    {!isValidName && (<span>{content?.errorTextName}</span>)}
                    <label htmlFor="company">
                      {content?.companyLabel}
                    </label>
                    <input
                      type="text"
                      onChange={(e) => { this.setState({ company: e.target.value }); }}
                      value={company}
                      placeholder={content?.companyPlaceholder}
                      id="company"
                    />
                    <label htmlFor="email">
                      {content?.mailLabel}
                    </label>
                    <input
                      type="email"
                      ref={(el) => {
                        if (el) this.emailEl = el;
                      }}
                      onChange={(e) => { this.setState({ email: e.target.value, isValidEmail: true }); }}
                      value={email}
                      placeholder={content?.mailPlaceholder}
                      id="email"
                    />
                    {!isValidEmail && (<span>{content?.errorTextEmail}</span>)}
                    <label htmlFor="phone">
                      {content?.phoneLabel}
                    </label>
                    <input
                      type="tel"
                      ref={(el) => {
                        if (el) this.phoneEl = el;
                      }}
                      onChange={(e) => { this.setState({ phone: e.target.value }); }}
                      value={phone}
                      placeholder={content?.phonePlaceholder}
                      id="phone"
                    />
                    {!isValidPhoneNumber && (<span>{content?.errorTextPhone}</span>)}
                    <label htmlFor="message">
                      {content?.messageLabel}
                    </label>
                    <textarea
                      rows={3}
                      ref={(el) => {
                        if (el) this.messageEl = el;
                      }}
                      onChange={(e) => { this.setState({ message: e.target.value, isValidMessage: true }); }}
                      value={message}
                      placeholder={content?.messagePlaceholder}
                      id="message"
                    />
                    {!isValidMessage && (<span>{content?.errorTextMessage}</span>)}
                    <p>{content?.requiredFields}</p>
                    { error && (<span>{content?.errorText}</span>)}
                    <SubmitButton
                      type="submit"
                    >
                      {content?.submitButton}
                    </SubmitButton>
                  </ContactFormContainer>
                </ContactForm>
              </ContactContainer>
            </TextContainer>
            <ImageWrapper>
              <ContactPageMonitor />
              <ScreenContainer url={content?.contactImageScreen} />
            </ImageWrapper>
          </WidthLimitationContainer>
        </ContactBackground>
      </Layout>
    );
  }
}

export const query = graphql`
query ContactQuery($locale: String!) {
  mdx(
    fields: { locale: { eq: $locale } },
    frontmatter: { layout: { eq: "contact" } }
  ) {
    frontmatter {
      title
      snippetTextContact
      headline
      subtext
      nameLabel
      namePlaceholder
      companyLabel
      companyPlaceholder
      mailLabel
      mailPlaceholder
      phoneLabel
      phonePlaceholder
      messageLabel
      messagePlaceholder
      requiredFields
      submitButton
      successText
      errorText
      errorTextName
      errorTextEmail
      errorTextPhone
      errorTextMessage
      contactImageScreen
    }
  }
}
`;
